import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import ReactPlayer from 'react-player';
import ReactMarkdown from 'react-markdown';
import Header from '../components/Header';
import {
  SectionComponent,
  ParagraphComponent,
  ButtonComponent,
  HeadlineComponent,
} from '../styles/StyledComponents';
import SEO from '../utils/seo';
import Layout from '../layout/Layout';
import { whatIsTP } from '../texts/mediapress';

export const Container = styled.div`
  margin: 3rem 1.5rem;
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  padding: 3rem 1.5rem;
`;

export const Divider = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.green};
  margin: 3rem;
`;
export const Branding = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    max-width: 200px;
    margin: 0 auto;
    & > div {
      width: 200px !important;
    }
  }
`;

export const VideoComponent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 300px;

    & > div {
      margin-bottom: 1rem;
      width: 100px;
      position: relative;
    }
  }
`;

export const Markdown = styled.div`
  ul {
    width: 90% !important;
    margin-left: 3rem !important;
  }
  ul li {
    list-style-type: circle;
    margin-bottom: 1.5rem !important;
  }
  p {
    margin-bottom: 1.5rem;
  }
  a {
    color: ${({ theme }) => theme.colors.green};
  }
`;

export const Products = styled.div`
  margin: 0 auto;
  text-align: left;
  max-width: 1000px;

  h1 {
    margin-bottom: 1.5rem;
    font-weight: 500;
  }

  ul {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    width: 600px;
    margin: 0 auto;
  }
  ul li {
    margin-bottom: 0.8rem;
    font-size: 1.1rem;
  }

  @media screen and (max-width: 768px) {
    width: 300px;

    ul {
      width: 320px;
    }
  }
`;

function PartnersPage({ data: md }) {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "presskit-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      TPLogo: file(relativePath: { eq: "TP-Logo.png" }) {
        childImageSharp {
          fixed(width: 600, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      FantasyTP: file(relativePath: { eq: "FantasyTP.png" }) {
        childImageSharp {
          fixed(width: 600, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      FlagTP: file(relativePath: { eq: "FlagTP.png" }) {
        childImageSharp {
          fixed(width: 600, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      ATP: file(relativePath: { eq: "ATP.png" }) {
        childImageSharp {
          fixed(width: 600, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      weMakePokerATeamSport: file(
        relativePath: { eq: "we-make-poker-a-team-sport.png" }
      ) {
        childImageSharp {
          fixed(width: 600, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      WPTC: file(relativePath: { eq: "WPTC.png" }) {
        childImageSharp {
          fixed(width: 600, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO
        title="Press Kit"
        link="https://teampoker.com/presskit"
        description="TeamPoker® is “The Patented Card Game Process” for the Tournament Poker Team Sports Model."
      />
      <Header image={data.background.childImageSharp.fluid} text="PRESS KIT" />
      <SectionComponent mg="1.5rem">
        <HeadlineComponent>Team Poker® Press Kit</HeadlineComponent>
        <ParagraphComponent pb="2rem">
          TeamPoker® is “The Patented Card Game Process” for the Tournament
          Poker Team Sports Model.
        </ParagraphComponent>
        <a
          href="https://drive.google.com/drive/folders/15YQZkx4lkbk1_lZu4EHQYRRbg5R6v8iX?usp=sharing"
          rel="noreferrer"
          target="_blank"
        >
          <ButtonComponent>Download Presskit</ButtonComponent>
        </a>
        <Container marginTop="1.5rem">
          <Products>
            <h1>Team Poker® Products</h1>
            <ul>
              <li>• Poker Tournament Management Software – Limited Licenses</li>
              <li>• TEAM POKER® Championship Licenses (Live & Online)</li>
              <li>• Merchandising</li>
              <li>• Poker Tournament Management Software – Limited Licenses</li>
              <li>
                • FREE CASINOWARE for small Poker Rooms 100 PLAYERS – 3 TABLES
              </li>
            </ul>
          </Products>
          <Divider />
          <Products>
            <h1>Trademarks</h1>
            <Branding>
              <Img
                style={{ display: 'block', marginBottom: 40 }}
                fixed={data.TPLogo.childImageSharp.fixed}
              />
              <Img
                style={{ display: 'block', marginBottom: 40 }}
                fixed={data.FlagTP.childImageSharp.fixed}
              />
              <Img
                style={{ display: 'block', marginBottom: 40 }}
                fixed={data.FantasyTP.childImageSharp.fixed}
              />
              <Img
                style={{ display: 'block', marginBottom: 40 }}
                fixed={data.ATP.childImageSharp.fixed}
              />
              <Img
                style={{ display: 'block', marginBottom: 40 }}
                fixed={data.weMakePokerATeamSport.childImageSharp.fixed}
              />
              <Img
                style={{ display: 'block', marginBottom: 40 }}
                fixed={data.WPTC.childImageSharp.fixed}
              />
            </Branding>
          </Products>
          <Divider />
          <Products>
            <h1>Media</h1>
            <VideoComponent>
              <ReactPlayer
                width="400px"
                height="270px"
                url="https://vimeo.com/437131089"
              />
              <ReactPlayer
                width="400px"
                height="270px"
                url="https://vimeo.com/325007962"
              />
            </VideoComponent>
            <VideoComponent>
              <ReactPlayer
                width="400px"
                height="270px"
                url="https://vimeo.com/285564371"
              />
              <ReactPlayer
                width="400px"
                height="270px"
                url="https://www.youtube.com/watch?v=OioAjZ-waPA"
              />
            </VideoComponent>
          </Products>
          <Divider />
          <Products>
            <h1>What is Team Poker®</h1>
            <Markdown>
              <ReactMarkdown source={whatIsTP()} />
            </Markdown>
          </Products>
        </Container>
        <a
          href="https://drive.google.com/drive/folders/15YQZkx4lkbk1_lZu4EHQYRRbg5R6v8iX?usp=sharing"
          rel="noreferrer"
          target="_blank"
        >
          <ButtonComponent>Download Presskit</ButtonComponent>
        </a>
      </SectionComponent>
    </Layout>
  );
}

export default PartnersPage;
