import { getYear } from '../helpers/date';

export const whatIsTP = () => {
  return `

1. **INDIVIDUAL and TEAM - SCORING & PAYOUT SYSTEM:** Any Structured Tournament Poker Game, and Weighted Payout is possible, 0 to 100%, to either category in Consolidation Tournaments (e.g., 60% payout to Teams - 40% to Individuals; or, 50% - 50%; or, 100% - 0%, etc.). Learn more specifics about the patented TeamPoker® System (TP) at www.teampoker.com.

&nbsp;

_**Example**: The inverse of the Individual Finish Positions (IFP) creates the team points for accumulative team score. With 100 players in the finish count, first player out receives 1 point, second 2 points, etc. until winning player receives 100 points for the team and highest team point scores win_.\

\
&nbsp;

**CARRY THE FLAG/ FLAG TP**: An Optional TeamPoker® method is “Carry-The-Flag” (CTF) where the top Individual Finish Positions determine Team Prize Pool Payout as well as Individual. The pre-published Individual and Team Splits are paid out like any tournament. There is no need to keep team score in CTF and the TP HANDICAPPING structure can still be used. 

2. **SEATING PROTOCOL**:  
- **No-Contact TP**: Teammates are never allowed on the same table with opponents. Teammates may combine and play on same table, heads-up, or among themselves. The tournament tables play until further consolidation can take place, download [Seating Protocol PDF](https://drive.google.com/file/d/1L1xLjGUz82z99NHdtTAOsXGcIvH96sUl/view?usp=sharing).    
- **Full-Contact TP**: Teammates are known and identified and can play on the same table with opponents.  

3. **TP HANDICAP**:  The optional TeamPoker® Handicap allows any size team to compete and team size to vary in the same tournament. The “Reverse Handicap” (max number of players per team/ actual number) allows smaller sized teams and individuals to maximize their skills (e.g., 3 players max – 1 player min; or 4 players max – 2 players min; etc.). The total team IFPs are multiplied by the Team Handicap to equalize their finish positions, and level the playing field. The team score tie breaker is always the team with the higher IFP. The TP Handicap also provides Prop Betting Type teams CLASSES like: Men vs Women, Country vs Country, etc. Any Class of player is potentially possible. 

4. **DISPLAY and RANK**: The Tournament DISPLAY SCREEN Displays will Display both INDIVIDUAL and TEAM Positions / Rank in the Tournament Real Time. The Team side of the screen shows and contains “Points” and/or “Points + Chip Count” as in any tournament so the TEAM RANK display will show the combination of Both POINTS +CHIPS.  

5. **WHY TEAMPOKER®**:

  - **TEAM POKER®** provides Poker Tournaments the synergism and comradery that only a team sport can provide. WE MAKE POKER A TEAM SPORT!™.

  - **The patented scoring, handicapping, and seating protocol HAS ALL OTHERS COVERED.** TEAM POKER® intellectual property provides a system that can adhere strictly to all the tournament poker rules and etiquette in one tournament with no collusion potential among teammates and where all teammates participate and contribute to the success.

  -	**All the basic features of TP Scoring can also be MODIFIED to fit the best format of the room**: like when to start the count after late registration or another level; partial counts of teammates enter (i.e., 3 of top 4) … Any size team is possible in TeamPoker; allow team size to vary with TP Handicap; add TAG capabilities for players that bust out early; We can make the event a series of tournaments with different and with different games in each separate tables/ event; that combines in a Team and Individual Scoring Rank.

  -	**TEAMPOKER® is perfect for Odds Making and Betting for Poker Teams, Clubs, and Individuals**... Tons of Prop bet potential; and FULL CONTACT TP where teammates are known and identified. Full Contact TP might be a fun way to play … particularly the pros. Full-Contact TP is not “collusion” as it is not secretive but rather teammates are known and identified. 


6. **TEAM POKER® FANTASY**: The patented Poker Team Scoring System can also be used for Fantasy Poker Team events. Sports betting companies can integrate the system and allow betting on a range of team sizes and Class (e.g., Pro, Amateur, Women Vs Men, any team class and size is possible). The Fantasy Team Poker® process mirrors the live and online process but is comprised of fictional teams that can be created.

\
&nbsp;

**Note: The Team Poker® basic licensed software that can be downloaded at [www.teampoker.com](www.teampoker.com) is for single casino and local championships only. This basic license does not include State, Region, Country, or World TP licenses which shall be negotiated by separate agreement. All audio video rights shall be negotiated separately.**

**All Rights Reserved, TEAM POKER® © 1999-${getYear()}, USPTO Patent No: 7819735 Oct 2010**\


Contact [glyn@teampoker.com](mailto:glyn@teampoker.com) for additional licensing information.
`;
};
